import axios from "axios";

const apiURL = "https://api.vibe-stream.tv";

export const getFeaturedList = () => {
  return axios.get(apiURL + "/featured/videos");
};

export const getPlaylist = (id) => {
  return axios.get(`${apiURL}/playlist/${id}/videos`);
};

export const getSingleVideo = (uuid) => {
  return axios.get(`${apiURL}/v3/video/${uuid}`);
};

export const getAllPlaylist = () => {
  return axios.get(`${apiURL}/combined-categories`);
};

// data = {id: "videoId", device: "web", duration: "watchedDuration"}
export const recordVideoAnalytics = (data) => {
  return axios.post(`${apiURL}/video/analytics`, data);
}

export const getWebCategories = () => {
  return axios.get(`${apiURL}/web-categories`);
}
