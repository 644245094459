import React from 'react';
import logo from "./assets/Vibe-Stream-Logo.png";
import "./App.css";

const ContactUs = () => {
  return (
    <div style={{ backgroundColor: "black", height: "100vh" }}>
      <div className="img-con-header">
        <a href="/">
          <img
            className="imgLogo"
            style={{ margin: "2px auto 0px auto" }}
            src={logo}
            width="10%"
            alt="logo"
          />
        </a>
      </div>
      <div style={{ color: "white", width: "80%", margin: "0 auto" }}>
        <p className="font-semibold">Hello and welcome!</p>
        <p className="font-semibold">
          Thank you for vibing with us. We are stoked to have you here.
        </p>
        <p className="font-semibold">
          If you are an artist and would like to have your video on Vibe Stream
          please head over to Symphonic Distribution, or ask your label or
          distributor about integrating with Vibe Stream.
        </p>
        <p className="font-semibold">
          Otherwise, if you’re using Vibe Stream and have any questions or
          suggestions, please contact me via email: zach[@]vibe-stream.tv and
          you will be responded to within 24 hours.
        </p>
        <p className="font-semibold">
          All music videos are licensed. If you would like to reach us regarding
          licensing or have a DMCA related request please email: support [@]
          vibe-stream.tv
        </p>
        <p className="font-semibold">All the best,</p>
        <p className="font-semibold">Zach</p>
      </div>
      <footer
        style={{
          color: "#fff",
          position: "fixed",
          bottom: "0",
          width: "100%",
          textAlign: "center",
        }}
      >
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-termsandconditions.pdf">
          Terms
        </a>{" "}
        |{"  "}
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-PrivacyPolicy.pdf">
          Privacy Policy
        </a>{" "}
        |{"  "}
        <a href="/contactus">Contact Us</a>
      </footer>
    </div>
  );
};

export default ContactUs;