import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import SingleVideo from './SingleVideo';
import Playlist from './Playlist';
import ViewPlaylists from './ViewPlaylists';
import ContactUs from './ContactUs';

ReactDOM.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/video/*" element={<SingleVideo />} />
      <Route exact path="/allplaylists" element={<ViewPlaylists />} />
      <Route exact path="/playlist/*" element={<Playlist />} />
      <Route exact path="/contactus" element={<ContactUs />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);