import React, { useEffect, useState } from "react";
import {
  Button,
  Spinner,
} from "reactstrap";
import logo from "./assets/Vibe-Stream-Logo.png";
import vwAllIcn from "./assets/view-all-button.png";
import stoIcns from "./assets/app-store-logos.jpg";
import "./App.css";
import { getAllPlaylist } from "./service/featured";

const ViewPlaylists = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllPlaylist().then(res => {
      setCategories(res.data.data.categories);
    })
  }, [])
  
  return (
    <div style={{ backgroundColor: "black", padding: "20px" }}>
      <div className="img-con">
        <a href="/">
          <img
            className="imgLogoVA"
            style={{ margin: "-18px auto" }}
            src={logo}
            width="10%"
            alt="logo"
          />
        </a>
      </div>
      {categories.length > 0 ? (
        <div>
          {categories.map((record, idx) => {
            return (
              <div>
                <h2
                  style={{ color: "#ff71ee", marginTop: "30px" }}
                  className="font-eagle-bold"
                >
                  {record.name}
                </h2>
                <div className="grid">
                  {record.playlists.map((playlist, idy) => {
                    const path = `/playlist/${playlist.id}`;
                    return (
                      <div className="plistInfo">
                        <img src={playlist.image_url} alt={playlist.name} />
                        <br />
                        <a href={path}>
                          <Button>Watch</Button>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Spinner color="warning">Loading....</Spinner>
      )}
      {/* <div className="img-con">
        <a href="https://app.gogopix.co/gogopixtv">
          <img
            className="imgBtn"
            style={{ marginTop: "45px" }}
            src={stoIcns}
            width="30%"
            alt="get the app logo"
          />
        </a>
      </div> */}
      <footer style={{ textAlign: "center", color: "#fff" }}>
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-termsandconditions.pdf">
          Terms
        </a>{" "}
        |{"  "}
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-PrivacyPolicy.pdf">
          Privacy Policy
        </a>{" "}
        |{"  "}
        <a href="/contactus">Contact Us</a>
      </footer>
    </div>
  );
};

export default ViewPlaylists;