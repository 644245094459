import { useEffect, useRef, useState } from "react";
import { recordVideoAnalytics } from "../service/featured";

function useVideoAnalytics() {
  const [videoId, setVideoId] = useState(null);

  const watchedDuration = useRef(0);

  const durationRecorder = useRef();

  function startRecordingDuration(newVideoId) {
    if (videoId !== newVideoId) {
      setVideoId(newVideoId);
      watchedDuration.current = 0;
    }
    clearInterval(durationRecorder.current);
    durationRecorder.current = setInterval(() => {
      watchedDuration.current += 1;
    }, 1000);
  }

  function pauseRecordingDuration() {
    clearInterval(durationRecorder.current);
  }

  function resumeRecordingDuration() {
    durationRecorder.current = setInterval(() => {
      watchedDuration.current += 1;
    }, 1000);
  }

  function recordRecordingDuration() {
    setVideoId((prevVideoId) => {
      if (prevVideoId) {
        recordVideoAnalytics({
          id: prevVideoId,
          device: "web",
          duration: watchedDuration.current,
        });

        watchedDuration.current = 0;
        return null;
      }
      return prevVideoId;
    });
  }

  useEffect(() => {
    window.addEventListener("beforeunload", recordRecordingDuration);
    return () => {
      window.removeEventListener("beforeunload", recordRecordingDuration);
      recordRecordingDuration();
    };
  }, []);

  return {
    start: startRecordingDuration,
    pause: pauseRecordingDuration,
    resume: resumeRecordingDuration,
    record: recordRecordingDuration,
  };
}

export default useVideoAnalytics;
