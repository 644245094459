import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { saveAs } from "file-saver";
import logo from "./assets/Vibe-Stream-Logo.png";
import nxtIcn from "./assets/next-button.png";
import preIcn from "./assets/previous-button.png";
import playIcn from "./assets/play-button.png";
import vwAllIcn from "./assets/view-all-button.png";
import homBtn from "./assets/button-home-filled-outline.png";
import dldIcn from "./assets/download-icon.png";
import lkIcnBlk from "./assets/link-icon-blk.png";
import moreBtn from "./assets/More-button.png";
import videojs from "video.js";
import "videojs-playlist";
import "videojs-contrib-ads";
import "videojs-ima";
import "video.js/dist/video-js.css";
import "videojs-ima/dist/videojs.ima.css";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { getSingleVideo } from "./service/featured";
import useVideoAnalytics from "./hooks/useVideoAnalytics";
import { copyToClipboard } from "./helpers/globals";

const SingleVideo = () => {
  const videoRef = useRef(null);
  const [video, setVideo] = useState(null);
  const [curTitle, setCurTitle] = useState("");
  const [curArtist, setcurArtist] = useState("");
  const [singleThnail, setSingleThnail] = useState("");
  const [linkOut, setLink] = useState("");
  const [albumArt, setAlbumArt] = useState("");
  const [orlFile, setOrlFile] = useState("");
  const [isShare, setIsShare] = useState(false);
  const [isVideoFnd, setIsVideoFnd] = useState(true);
  const [modal, setModal] = useState(false);
  const [copiedTitle, setCopiedTitle] = useState("");

  const analytics = useVideoAnalytics();

  

  const toggle = () => setModal(!modal);

  const changeTitle = (title) => {
    setModal(!modal);
    setCopiedTitle(title);
  };

  const player = useRef(null);

  let IsNoVideo = ({ children }) => {
    return !isVideoFnd ? children : <></>;
  };

  const changeVideo = (id, e) => {
    const record = video
    analytics.record();
    player.current.play();
    
    record.is_playing = true;
    setVideo(record);
  };

  const showThumbnail = (id) => {
    const img = document.getElementById(`hoverimg${id}`);
    img.style.marginTop = 25 + "px";
    img.style.visibility = "visible";
  };

  const hideThumbnail = (id) => {
    const img = document.getElementById(`hoverimg${id}`);
    img.style.visibility = "hidden";
  };
  
  const downloadOriginal = () => {
    const arr = orlFile.split(".");
    saveAs(orlFile,
      `${curTitle.replaceAll(" ", "_")}.${arr[arr.length - 1]}`);
  };
    
  useEffect(() => {
    const googletag = window.googletag;
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-1699300221224-0");
    });

    let imaOptions = {
      adTagUrl:
        "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/22978710279/jp-test-1&ciu_szs=300x250&env=vp&impl=s&gdfp_req=1&output=vast&unviewed_position_start=1&url=[referrer_url]&description_url=[description_url]&correlator=[timestamp]",
      // disableCustomPlaybackForIOS10Plus: true,
    };
    const arr = window.location.href.split("/");
    let uuid = null;
    if (arr[arr.length - 1].includes("?") && arr[arr.length - 3].includes("video")) {
      uuid = arr[arr.length - 2];
    }
    else if (arr[arr.length - 2].includes("video")) {
      uuid = arr[arr.length - 1];
    }

    if (uuid) {
      imaOptions.adTagUrl =
        "https://pubads.g.doubleclick.net/gampad/ads?iu=/22978710279/halfpage-for-video&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&ciu_szs=300x600&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=";
      getSingleVideo(uuid)
        .then((res) => {
          setVideo(res.data.data);
          let record = res.data.data;
          setcurArtist(record.artist);
          setCurTitle(record.title);
          setSingleThnail(record.thumbnail_url);
          setLink(record.link_out);
          setAlbumArt(record.album_artwork);
          setIsShare(record.is_shareable);
          setOrlFile(record.original_file);
          const url = { src: record.video_url, type: "application/x-mpegURL" };

          const videoOp = {
            controls: true,
            controlBar: {
              pictureInPictureToggle: false,
            },
          };
          player.current = videojs(videoRef.current, videoOp);
          const Button = videojs.getComponent("Button");
          const preBtn = new Button(player.current, {});
          preBtn.controlText("Previous");
          preBtn.addClass("vjs-visible-text");

          const preBtnDom = preBtn.el();
          preBtnDom.innerHTML = `<img src=${preIcn} width="25%" />`;
          preBtnDom.onclick = function (e) {
            player.current.playlist.currentItem(
              player.current.playlist.previousIndex()
            );
          };
          player.current.getChild("ControlBar").addChild(preBtn, {}, 1);

          const nextBtn = new Button(player.current, {});
          nextBtn.controlText("Next");
          nextBtn.addClass("vjs-visible-text");

          const nextBtnDom = nextBtn.el();
          nextBtnDom.innerHTML = `<img src=${nxtIcn} width="25%" />`;
          nextBtnDom.onclick = function (e) {
            player.current.playlist.currentItem(
              player.current.playlist.nextIndex()
            );
          };
          player.current.getChild("ControlBar").addChild(nextBtn, {}, 2);
          // player.current.playlist(urls);
          // player.current.playlist.autoadvance(0);
          player.current.src(url)
          player.current.on("play", () => {
            analytics.start(record.id);
            setCurTitle(record.title);
            setcurArtist(record.artist);
            record.is_playing = true;
          });

          player.current.on("ended", () => {
            analytics.record();
          });
          player.current.ima(imaOptions);
          // player.current.ima.initializeAdDisplayContainer();
          // player.current.ima.requestAds();

          // let firInd = videos[0].video_url.lastIndexOf("/");
          // let ltInd = videos[0].video_url.lastIndexOf(".")
          player.current.on("touchstart", (e) => {
            if (e.target.classList.contains("vjs-playing")) {
              // this.bigPlayButton.show();
              analytics.pause();
              player.current.pause();
            } else {
              player.current.play();
            }
          });
          return () => {
            player.current.dispose();
          };
        })
        .catch((e) => {
          setIsVideoFnd(false);
          setVideo([]);
          console.log(e);
        });
    }
  }, []);
  return (
    <div style={{ backgroundColor: "black" }}>
      <div style={{ width: "100%" }}>
        <div className="img-con-header">
          <div className='link-con'>
            <div></div>
            <div style={{
              textAlign: "center",
              margin: "0 auto",
            }}>
              <a href="/">
                <img
                  className="imgLogo"
                  src={logo}
                  width="41%"
                  alt="logo"
                />
              </a>
            </div>
            <div style={{
              textAlign: "right",
              margin: "auto 0 0 auto",
            }}>
              <a href="/">
                <img
                  className="imgLogo"
                  src={homBtn}
                  width="41%"
                  alt="home"
                />
              </a>
            </div>
          </div>
        </div>
        <IsNoVideo>
          <div style={{ color: "white", margin: "4px auto", textAlign: "center" }}>
            <h2>
              Dang it, something went wrong. Please go to{" "}
              <a href="/">VIBE-STREAM.TV</a>
            </h2>
          </div>
        </IsNoVideo>
      </div>
      <div
        style={{
          color: "white",
          width: "80%",
          margin: "0 auto",
          padding: "0 0 2rem 0",
        }}
      >
        <h4 className="font-semibold">{curTitle}</h4>
        <h6>{curArtist}</h6>
        {isShare && window.innerWidth > 800 ? (
          <div
            style={{
              position: "relative",
              float: "right",
              marginRight: "25px",
              marginLeft: "auto",
              marginTop: "-48px"
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={dldIcn}
              alt='download'
              onClick={() => {
                downloadOriginal()
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="video-con">
        <video
          ref={videoRef}
          className="video-js vjs-16-9 vjs-default-skin vjs-big-play-centered single-video"
          poster={singleThnail}
        />
      </div>

      <div style={{ width: "100%", marginTop: "1rem" }}>
        {video ? (
          <Table
            className="custom-table"
            style={{
              tableLayout: "fixed",
              width: "80%",
              margin: "0 auto",
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "66px" }}></th>
                <th>Title</th>
                <th>Artist</th>
                {/* <th className="link-col">Link</th> */}
              </tr>
            </thead>

            <tbody>
              {(video.is_playing) ? (
                <tr id={video.id} key={video.id} className="rowSelected">
                  <td></td>
                  <td>
                    <span className='video-title'
                      onMouseOver={() => { showThumbnail(video.id) }}
                      onMouseOut={() => { hideThumbnail(video.id) }}
                    >{video.title}</span>
                    <img
                      id={`hoverimg${video.id}`}
                      className='hover-img'
                      src={video.thumbnail_url}
                      alt={`${video.title} thumbnail`} />
                  </td>
                  <td>{video.artist}</td>
                  <td>
                    <img
                      src={lkIcnBlk}
                      style={{ cursor: "pointer" }}
                      alt={`play ${video.title}`}
                      width="23px"
                      onClick={() => {
                        copyToClipboard(`${window.location.protocol}//${window.location.host}/video/${video.video_id}`);
                        changeTitle(video.title);
                      }}
                    />
                  </td>
                </tr>
              ) : (
                <tr id={video.id} key={video.id}>
                  <td>
                    <img
                      src={playIcn}
                      style={{ cursor: "pointer" }}
                      alt={`play ${video.title}`}
                      width="95%"
                      onClick={changeVideo.bind(this, video.id)}
                    />
                  </td>
                  <td>
                    <span className='video-title'
                      onMouseOver={() => { showThumbnail(video.id) }}
                      onMouseOut={() => { hideThumbnail(video.id) }}
                    >{video.title}</span>
                    <img
                      id={`hoverimg${video.id}`}
                      className='hover-img'
                      src={video.thumbnail_url}
                      alt={`${video.title} thumbnail`} />
                  </td>
                  <td>{video.artist}</td>
                  {/* <td>
                    <img
                      src={lkIcn}
                      style={{ cursor: "pointer" }}
                      alt={`play ${video.title}`}
                      width="23px"
                      onClick={() => {
                        copyToClipboard(`${window.location.protocol}/${window.location.host}/video/${video.video_id}`);
                        changeTitle(video.title);
                      }}
                    />
                  </td> */}
                </tr>
              )
              }
                
            </tbody>
          </Table>
        ) : (
          <Spinner color="warning">Loading....</Spinner>
        )}
      </div>

      {linkOut ? (
        <><div
          style={{
            color: "#ff71ee",
            width: "80%",
            margin: "0 auto",
            marginTop: "25px",
            textAlign: "center"
          }}
        >
          <a href={linkOut}>
            
            <img
              className='alb-img'
              src={video.album_artwork}
              alt="album artwork" />
          </a>
        </div>
        </>
      ) : albumArt ? (
        <><div
          style={{
            color: "#ff71ee",
            width: "80%",
            margin: "0 auto",
            marginTop: "25px",
            textAlign: "center"
          }}
        >
          <img
            className='alb-img'
            src={video.album_artwork}
            alt="album artwork" />
        </div>
        </>
      ) : (
        <></>
      )}

      <div className="img-con">
        <a href="/allplaylists">
          <img
            className="imgBtn"
            style={{ marginTop: "45px" }}
            src={vwAllIcn}
            width="20%"
            alt="view all"
          />
        </a>
      </div>
      {/* <div className="img-con">
        <a href="https://app.gogopix.co/gogopixtv">
          <img
            className="imgBtn"
            style={{ marginTop: "45px" }}
            src={stoIcns}
            width="30%"
            alt="get the app logo"
          />
        </a>
      </div> */}
      <footer style={{ textAlign: "center", color: "#fff" }}>
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-termsandconditions.pdf">
          Terms
        </a>{" "}
        |{"  "}
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-PrivacyPolicy.pdf">
          Privacy Policy
        </a>{" "}
        |{"  "}
        <a href="/contactus">
          Contact Us
        </a>
      </footer>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Video URL copied</ModalHeader>
        <ModalBody>
          The video url titled "{copiedTitle}" has been copied to the clipboard.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SingleVideo;